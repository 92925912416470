import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import Subscription from '../pages/subscription/Subscription';
import Loading from './Loading';

export default function RegisterWithoutPayment(props) {
    const {email_url, type, status} = useParams();
    const [clinicName, setClinicName] = useState('');
    const [email, setEmail] = useState(email_url ? email_url : "");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [ready, setReady] = useState(true);
    const [error, setError] = useState([]);
    const [activeScreen, setActiveScreen] = useState('signup');
    const history = useHistory();


    const signUp = () => {
        if (isSignUpValid()) {
          setMessage("");
          setError([]);
          setReady(false);
          fetch(`${process.env.REACT_APP_API}/clinic/register`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: clinicName.trim(),
              email: email.trim().toLowerCase(),
              password: password,
            }),
          })
            .then(response => response.json())
            .then(response => {
              if (response.success) {
                localStorage.setItem('access_token', response.access_token);
                localStorage.setItem('expires_at', (response.expires_at * 1000));
                props.setLoggedIn(true);
                history.push("/onboarding/user-agreement");
              } else {
                setMessage(response.message);
                setError(response.error);
                setReady(true);
              } 
            })
            .catch(error => {
              console.error(error);
              setMessage('Some Error Occured. Please Try Again Later');
              setReady(true);
            });
        }
    };
 
    const isSignUpValid = () => {
      if(password === confirmPassword){
        return true;
      }
      setError({confirmPassword: "Password does not match confirm password"});
      return false;
    };

    return (
        <>
          <main className={`flex flex-col p-4 ${ready ? '' : 'hidden'}`}>
           
            <div className="my-auto flex flex-col">
                <NavLink to="/" className="mx-auto mb-12">
                    <img className="h-32" src={Logo} alt="GRO Track" />
                </NavLink>
                {message && <p className="text-center text-red-600">{message}</p>}
                <form
                    className="mt-4 flex flex-col items-center"
                    onSubmit={event => {
                      event.preventDefault();
                      signUp();
                    }}
                >
                    <input
                      type="text"
                      className="px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                      placeholder="clinic name"
                      value={clinicName}
                      required
                      onChange={event => {
                        setClinicName(event.target.value);
                      }}
                    />
                    {error.name && <p className="text-center text-red-600">{error.name}</p>}
                    <input
                      type="email"
                      className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                      placeholder="email"
                      value={email}
                      required
                      onChange={event => {
                        setEmail(event.target.value);
                      }}
                    />
                    {error.email && <p className="text-center text-red-600">{error.email}</p>}

                    <input
                      type="password"
                      className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                      placeholder="password"
                      value={password}
                      minLength="8"
                      required
                      onChange={event => {
                        setPassword(event.target.value);
                      }}
                    />
                    {error.password && <p className="text-center text-red-600">{error.password}</p>}
                    <input
                      type="password"
                      className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                      placeholder="confirm password"
                      value={confirmPassword}
                      minLength="8"
                      required
                      onChange={event => {
                        setConfirmPassword(event.target.value);
                      }}
                    />
                    {error.confirmPassword && <p className="text-center text-red-600">{error.confirmPassword}</p>}
                    
                    <input
                      type="submit"
                      className="mt-8 h-20 w-20 rounded-full bg-accent-1 text-text-3 cursor-pointer focus:outline-none"
                      value="Next"
                    />
                </form>
                
            </div>
          </main>
          <Loading
            message='Registering Clinic'
            ready={ready}
          />
          <footer className="px-4 pt-4 pb-8 border-t-2 border-accent-2 text-center">
            GRO technologies
          </footer>
        </>
    );
}