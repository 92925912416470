import React, { useState, useEffect } from "react";
import PresentationNavbar from "./PresentationNavbar";
import OverviewProgress from "../OverviewProgress";

const HairOverview = ({ id, setMessage, setReady, setView }) => {
  const [viewMode, setViewMode] = useState("front");
  // const [indexMode, setIndexMode] = useState("density");
  const [hairAnalysis, setHairAnalysis] = useState([]);
  const [currentId, setCurrentId] = useState("-1");
  const stackedData = [1, 2, 3, 4, 5, 6, 7];
  const [image, setImage] = useState("");

  const chartColor = [
    "#EF8679",
    "#F7A79D",
    "#F7D2CE",
    "#FFFFFF",
    "#B4F8F2",
    "#1D968B",
    "#3DE2D2",
  ];

  const getImagePath = (array, mainType, subType) => {
    for (var index = 0; index < array.length; index++) {
      if (
        array[index].mainType === mainType &&
        array[index].subType === subType
      ) {
        return array[index].image_path;
      }
    }
    return null;
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setHairAnalysis(response.hair_analysis);
          setCurrentId(0);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
      });
  }, [id, setMessage, setReady]);

  useEffect(() => {
    setViewMode("front");
    if (hairAnalysis && hairAnalysis[currentId]) {
      setImage(
        getImagePath(
          hairAnalysis[currentId].images.portrait,
          "portrait",
          "frontal"
        )
      );
    }
  }, [currentId, hairAnalysis]);

  useEffect(() => {
    if (hairAnalysis[0]) {
      setImage(
        getImagePath(hairAnalysis[0].images.portrait, "portrait", "frontal")
      );
    }
  }, [hairAnalysis]);

  return (
    <>
      <div
        className="p-12 bg-background-5 overflow-hidden"
        style={{ height: "var(--height-2)" }}
      >
        <div className="flex items-center justify-center mx-auto text-text-8 font-font-2">
          <button
            className={`${
              viewMode === "front" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("front");
              setImage(
                getImagePath(
                  hairAnalysis[currentId].images.portrait,
                  "portrait",
                  "frontal"
                )
              );
            }}
          >
            Front
          </button>
          <button
            className={`${
              viewMode === "crown" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("crown");
              setImage(
                getImagePath(
                  hairAnalysis[currentId].images.portrait,
                  "portrait",
                  "crown"
                )
              );
            }}
          >
            Crown
          </button>
          <button
            className={`${
              viewMode === "vertex" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("vertex");
              setImage(
                getImagePath(
                  hairAnalysis[currentId].images.portrait,
                  "portrait",
                  "vertex"
                )
              );
            }}
          >
            Vertex
          </button>
          <button
            className={`${
              viewMode === "back" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("back");
              setImage(
                getImagePath(
                  hairAnalysis[currentId].images.portrait,
                  "portrait",
                  "back"
                )
              );
            }}
          >
            Back
          </button>
          <button
            className={`${
              viewMode === "left" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("left");
              setImage(
                getImagePath(
                  hairAnalysis[currentId].images.portrait,
                  "portrait",
                  "left"
                )
              );
            }}
          >
            Left
          </button>
          <button
            className={`${
              viewMode === "right" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("right");
              setImage(
                getImagePath(
                  hairAnalysis[currentId].images.portrait,
                  "portrait",
                  "right"
                )
              );
            }}
          >
            Right
          </button>
        </div>
        <div className="mt-10 h-full grid grid-cols-3 gap-4">
          <div className="flex items-center justify-center">
            <div className="self-start">
              <h2 className="text-xl font-bold text-center text-text-2">
                GRO Index
              </h2>
              <div className="mt-4 flex flex-col items-center">
                {/* <div className="flex flex-row items-center text-text-8"> */}
                  {/* <button
                    className={`focus:outline-none ${
                      indexMode === "line" && "text-text-7"
                    }`}
                    onClick={() => setIndexMode("line")}
                  >
                    Hair Line index
                  </button> */}
                  {/* <button
                    className={`ml-8 focus:outline-none ${
                      indexMode === "density" && "text-text-7"
                    }`}
                    onClick={() => setIndexMode("density")}
                  >
                    Hair Density Index
                  </button> */}
                {/* </div> */}
                {
                  <div className="flex flex-col mt-8">
                    {stackedData.map((data, index) => {
                      return (
                        <div
                          className="flex flex-row relative hover:text-accent-3"
                          key={index}
                        >
                          <div
                            style={{
                              backgroundColor: chartColor[index],
                            }}
                            className="flex items-center justify-center w-16 h-8 text-text-6 font-normal transform border-2 border-transparent hover:font-bold hover:scale-125 hover:z-10 hover:rounded-lg hover:shadow-strong hover:border-white hover:text-white bg-background-3"
                          >
                            {data}
                          </div>
                          <div className="flex items-center justify-center w-16 h-8 px-3 text-xs leading-none text-center ">
                            100% increase
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="mx-auto">
              {image && <img src={image} alt="" className="h-96" />}

              {!image && <p>No image uploaded</p>}
              {/* {image} */}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-full h-full flex flex-col items-center justify-center">
              <button
                className="mb-4 font-bold text-background-17 focus:outline-none"
                onClick={() => setView("view-treatment-plan")}
              >
                View Treatment Plan
              </button>
              <OverviewProgress
                title="Treatment Progress"
                subTitle="76% completed"
                progress={0.2}
              />
              <div className="my-3" />
              <OverviewProgress
                title="Home Care Progress"
                subTitle="0 months"
                progress={0}
              />
            </div>
          </div>
        </div>
      </div>
      <PresentationNavbar tab={1} setView={setView} />
    </>
  );
};

export default HairOverview;
