import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Loading from "../views/Loading";
import DotGrey from "../assets/images/DotGrey.svg";
import DotTurquoise from "../assets/images/DotTurquoise.svg";

export default function PatientReferrals() {
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState([]);
  const [salons, setSalons] = useState([]);
  const [affiliates, setAffiliates] = useState("all");
  const [requests, setRequests] = useState([]);
  const [activeTab, setActiveTab] = useState('referrals');

  useEffect(() => {
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/patient/referrals`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        salon_id: affiliates,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPatientReferrals(response.patients);
          setSalons(response.salons);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  }, [affiliates]);

  useEffect(() => {
    consumerReferrals();
  },[])

  const consumerReferrals = () => {
    fetch(`${process.env.REACT_APP_API}/patient/appointment-requests`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // setPatientReferrals(response.patients);
          // setSalons(response.salons);
          setRequests(response.data);
          console.log(response);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  };

  const [selected, setSelected] = useState(null);

  const handleSelect = (value) => {
    setSelected(value);
  };

  return (
    <>
      <main
        className={`container mx-auto flex flex-col p-4 text-text-2 ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex pb-6 border-b-2 border-background-2">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
        </nav>
        {message && <p className="mt-4 text-center text-red-600">{message}</p>}
        
        <div className="flex flex-grow  w-full gap-16">
          <div className="w-1/6 border-r-2 border-background-2 p-6">
            <h1 className="text-left font-bold text-xl leading-5 font-normal font-roboto tracking-widest text-white opacity-80 border-b-2 border-solid border-background-2 py-3">Affiliates</h1>
            <div>
              <p className={`text-left text-xl leading-5 font-normal font-roboto tracking-widest text-white ${affiliates === 'all' && activeTab === 'referrals' ? 'opacity-80' : 'opacity-30'} my-3 cursor-pointer`} onClick={() => {setAffiliates('all'); setActiveTab('referrals')}}>All</p>
              {salons.map((salon) => {
                return <p className={`text-left text-xl leading-5 font-normal font-roboto tracking-widest text-white ${affiliates === salon.salon_id && activeTab === 'referrals' ? 'opacity-80' : 'opacity-30'} my-3 cursor-pointer`} onClick={() => {setAffiliates(salon.salon_id); setActiveTab('referrals')}}>{salon.stylist_name}</p>
              })}
            </div>
            <h1 className="text-left font-bold text-xl leading-5 font-normal font-roboto tracking-widest text-white opacity-80 border-b-2 border-solid border-background-2 py-3 mt-16 cursor-pointer" onClick={() => {setActiveTab('consumers')}}>Consumers</h1>
          </div>
          <div className="w-5/6 flex-grow p-6">
              <div>
                <h1 className="text-center font-bold text-xl text-base leading-5 font-normal font-roboto tracking-wide text-white opacity-100 capitalize py-3">
                  {activeTab === 'referrals' && <div>Affiliate New Patient Referrals ({affiliates === 'all' ? 'All' : salons.find((s) => s.salon_id === affiliates).stylist_name})</div>}
                  {activeTab === 'consumers' && <div>Direct Consumer Referrals</div>}
                </h1>
              </div>
              <div className="my-8">
                <div className="flex justify-around w-3/4 mx-auto">
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 hidden"
                      value="all"
                      checked={selected === "all"}
                      onChange={() => handleSelect("all")}
                    />
                    {selected === "all" ? <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' /> : <img src={DotGrey} className='h-3 w-3 cursor-pointer' />}
                    <span className="ml-2 text-left text-base leading-4 font-normal font-sans tracking-wider text-gray-100 opacity-70 cursor-pointer">All</span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 hidden"
                      value="review"
                      checked={selected === "review"}
                      onChange={() => handleSelect("review")}
                    />
                    {selected === "review" ? <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' /> : <img src={DotGrey} className='h-3 w-3 cursor-pointer' />}
                    <span className="ml-2 text-left text-base leading-4 font-normal font-sans tracking-wider text-gray-100 opacity-70 cursor-pointer">Review</span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 hidden"
                      value="pending"
                      checked={selected === "pending"}
                      onChange={() => handleSelect("pending")}
                    />
                    {selected === "pending" ? <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' /> : <img src={DotGrey} className='h-3 w-3 cursor-pointer' />}
                    <span className="ml-2 text-left text-base leading-4 font-normal font-sans tracking-wider text-gray-100 opacity-70 cursor-pointer">Pending</span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 hidden"
                      value="scheduled"
                      checked={selected === "scheduled"}
                      onChange={() => handleSelect("scheduled")}
                    />
                    {selected === "scheduled" ? <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' /> : <img src={DotGrey} className='h-3 w-3 cursor-pointer' />}
                    <span className="ml-2 text-left text-base leading-4 font-normal font-sans tracking-wider text-gray-100 opacity-70 cursor-pointer">Scheduled</span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 hidden"
                      value="shown"
                      checked={selected === "shown"}
                      onChange={() => handleSelect("shown")}
                    />
                    {selected === "shown" ? <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' /> : <img src={DotGrey} className='h-3 w-3 cursor-pointer' />}
                    <span className="ml-2 text-left text-base leading-4 font-normal font-sans tracking-wider text-gray-100 opacity-70 cursor-pointer">Shown</span>
                  </label>
                </div>
              </div>
              <div className="my-16">
                {activeTab === 'referrals' && 
                  <section className="mt-10 items-end grid gap-6">
                    {patientReferrals.map(patient => {
                      return (
                        <div
                          key={patient.id}
                          className="flex items-end justify-evenly"
                        >
                          <p className="ml-4 mr-8 p-2 w-96 bg-background-2 text-text-7 rounded-sm flex items-center justify-evenly">
                            <span className="truncate">
                              {patient.firstName} referral {patient.lastName}
                            </span>
                            <span className="font-normal text-sm">
                              DOB: {patient.dob}
                            </span>
                          </p>
                          <NavLink
                            to={`/patient/${patient.id}/consult/0/global/crown`}
                            className={`font-thin tracking-widest h-10 w-32 flex items-center justify-center bg-accent-1 rounded-lg text-sm text-text-3 focus:outline-none`}
                          >
                            Review
                          </NavLink>
                        </div>
                      );
                    })}
                  </section>
                }
                {activeTab === 'consumers' && 
                  <section className="mt-10 items-end grid gap-6">
                    {requests.map(patient => {
                      return (
                        <div
                          key={patient.patient.id}
                          className="flex items-end justify-evenly"
                        >
                          <p className="ml-4 mr-8 p-2 w-96 bg-background-2 text-text-7 rounded-sm flex items-center justify-evenly">
                            <span className="truncate">
                              {patient.patient.firstName} {patient.patient.lastName}
                            </span>
                            <span className="font-normal text-sm">
                              DOB: {patient.patient.dob}
                            </span>
                          </p>
                          <NavLink
                            to={`#`}
                            className={`font-thin tracking-widest h-10 w-32 flex items-center justify-center bg-accent-1 rounded-lg text-sm text-text-3 focus:outline-none`}
                          >
                            Review
                          </NavLink>
                        </div>
                      );
                    })}
                  </section>
                }
              </div>
          </div>
        </div>
      </main>
      <Loading message="Loading Patient Referrals" ready={ready} />
    </>
  );
}
