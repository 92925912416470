import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";

export default function AdminProcedure() {
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);

  const [procedures, setProcedures] = useState([]);
  const [newProcedure, setNewProcedure] = useState({
    type: "PRP",
    name: "",
    brand: "",
    bloodV: "",
    plasmaV: "",
    plateletC: "",
    injectionDepth: "",
    fee: "",
    time:0
  });
  const [editProcedure, setEditProcedure] = useState(null);

  const fetchProcedures = () => {
    fetch(`${process.env.REACT_APP_API}/clinic/procedures`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setProcedures(response.procedures);
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
      })
      .finally(() => setReady(true));
  };

  const handleSubmit = () => {
    if (editMode) {
      setReady(false);
      fetch(
        `${process.env.REACT_APP_API}/clinic/procedures/${editProcedure.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(editProcedure),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setEditMode(false);
            setEditProcedure(null);
            setSuccess("Procedure saved successfully");
            fetchProcedures();
          } else {
            setMessage(response.message);
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    } else {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/clinic/procedures`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(newProcedure),
      })
        .then((response) => response.json())
        .then((response) => {
          setNewProcedure({
            type: "PRP",
            name: "",
            brand: "",
            bloodV: "",
            plasmaV: "",
            plateletC: "",
            injectionDepth: "",
            fee: "",
            time: 0
          });
          if (response.success) {
            setSuccess("New procedure added successfully");
            fetchProcedures();
          } else {
            setMessage(response.message);
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetchProcedures();
  }, []);

  useEffect(() => {
    if (editProcedure) setEditMode(true);
  }, [editProcedure]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}>
        <nav className="flex items-center border-b-2 border-background-2 pb-4">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
          <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
            PACIFIC HAIR CENTER
          </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex">
          <AdminSidebar />
          <div className="mx-auto">
            <form
              className="mt-4 flex flex-col"
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}>
              <div className="flex items-center">
                <button
                  type="button"
                  className={`px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none ${!editMode && "hidden"}`}
                  onClick={() => {
                    setEditMode(false);
                    setEditProcedure(null);
                  }}>
                  Cancel
                </button>
                <p className="mx-auto text-text-2">SET UP PROCEDURE</p>
                <button
                  className="px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none">
                  {editMode ? "SAVE" : "ADD"}
                </button>
              </div>
              <div className="mt-4 flex justify-evenly">
                <button
                  type="button"
                  className={`font-bold focus:outline-none border-b-2 border-transparent text-center text-lg ${
                    (editMode
                      ? editProcedure.type === "PRP"
                      : newProcedure.type === "PRP") &&
                    "text-accent-1 border-accent-1"
                  }`}
                  onClick={() => {
                    editMode
                      ? setEditProcedure({
                          ...editProcedure,
                          type: "PRP",
                        })
                      : setNewProcedure({
                          ...newProcedure,
                          type: "PRP",
                        });
                  }}>
                  PRP
                </button>
                <button
                  type="button"
                  className={`font-bold focus:outline-none border-b-2 border-transparent text-center text-lg ${
                    (editMode
                      ? editProcedure.type === "EXOSOMES"
                      : newProcedure.type === "EXOSOMES") &&
                    "text-accent-1 border-accent-1"
                  }`}
                  onClick={() => {
                    editMode
                      ? setEditProcedure({
                          ...editProcedure,
                          type: "EXOSOMES",
                        })
                      : setNewProcedure({
                          ...newProcedure,
                          type: "EXOSOMES",
                        });
                  }}>
                  EXOSOMES
                </button>
                <button
                  type="button"
                  className={`font-bold focus:outline-none border-b-2 border-transparent text-center text-lg ${
                    (editMode
                      ? editProcedure.type === "STEMCELL"
                      : newProcedure.type === "STEMCELL") &&
                    "text-accent-1 border-accent-1"
                  }`}
                  onClick={() => {
                    editMode
                      ? setEditProcedure({
                          ...editProcedure,
                          type: "STEMCELL",
                        })
                      : setNewProcedure({
                          ...newProcedure,
                          type: "STEMCELL",
                        });
                  }}>
                  STEMCELL
                </button>
                <button
                  type="button"
                  className={`font-bold focus:outline-none border-b-2 border-transparent text-center text-lg ${
                    (editMode
                      ? editProcedure.type === "CUSTOM"
                      : newProcedure.type === "CUSTOM") &&
                    "text-accent-1 border-accent-1"
                  }`}
                  onClick={() => {
                    editMode
                      ? setEditProcedure({
                          ...editProcedure,
                          type: "CUSTOM",
                        })
                      : setNewProcedure({
                          ...newProcedure,
                          type: "CUSTOM",
                        });
                  }}>
                  CUSTOM
                </button>
              </div>
              <div className="mx-auto mt-10 flex flex-col items-end">
                <label className="mt-4 flex items-center">
                  <p className="ml-2">Procedure Name</p>
                  <input
                    type="text"
                    className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={editMode ? editProcedure.name : newProcedure.name}
                    onChange={(event) => {
                      editMode
                        ? setEditProcedure({
                            ...editProcedure,
                            name: event.target.value,
                          })
                        : setNewProcedure({
                            ...newProcedure,
                            name: event.target.value,
                          });
                    }}
                  />
                </label>
                <label className="mt-4 flex items-center">
                  <p className="ml-2">Brand Name</p>
                  <input
                    type="text"
                    className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={editMode ? editProcedure.brand : newProcedure.brand}
                    onChange={(event) => {
                      editMode
                        ? setEditProcedure({
                            ...editProcedure,
                            brand: event.target.value,
                          })
                        : setNewProcedure({
                            ...newProcedure,
                            brand: event.target.value,
                          });
                    }}
                  />
                </label>
                <div className="mt-4 flex">
                  <p className="ml-2 mt-1">Protocol</p>
                  <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2 mb-4">
                    <label className="flex items-center justify-items-center">
                      <p>Blood V.</p>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                        value={
                          editMode ? editProcedure.bloodV : newProcedure.bloodV
                        }
                        onChange={(event) => {
                          editMode
                            ? setEditProcedure({
                                ...editProcedure,
                                bloodV: event.target.value,
                              })
                            : setNewProcedure({
                                ...newProcedure,
                                bloodV: event.target.value,
                              });
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2 mb-4">
                  <label className="flex items-center justify-items-center">
                    <p>Plasma V.</p>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        editMode ? editProcedure.plasmaV : newProcedure.plasmaV
                      }
                      onChange={(event) => {
                        editMode
                          ? setEditProcedure({
                              ...editProcedure,
                              plasmaV: event.target.value,
                            })
                          : setNewProcedure({
                              ...newProcedure,
                              plasmaV: event.target.value,
                            });
                      }}
                    />
                  </label>
                </div>
                <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2 mb-4">
                  <label className="flex items-center justify-items-center">
                    <p>Platelet C.</p>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editProcedure.plateletC
                          : newProcedure.plateletC
                      }
                      onChange={(event) => {
                        editMode
                          ? setEditProcedure({
                              ...editProcedure,
                              plateletC: event.target.value,
                            })
                          : setNewProcedure({
                              ...newProcedure,
                              plateletC: event.target.value,
                            });
                      }}
                    />
                  </label>
                </div>
                <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2 mb-4">
                  <label className="flex items-center justify-items-center">
                    <p>Injection Depth</p>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editProcedure.injectionDepth
                          : newProcedure.injectionDepth
                      }
                      onChange={(event) => {
                        editMode
                          ? setEditProcedure({
                              ...editProcedure,
                              injectionDepth: event.target.value,
                            })
                          : setNewProcedure({
                              ...newProcedure,
                              injectionDepth: event.target.value,
                            });
                      }}
                    />
                  </label>
                </div>
                <label className="mt-4 ml-24 flex items-center self-start">
                  <p className="ml-3">Fee</p>
                  <input
                    type="number"
                    className="ml-6 md:w-46 font-bold w-40 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                    value={editMode ? editProcedure.fee : newProcedure.fee}
                    onChange={(event) => {
                      editMode
                        ? setEditProcedure({
                            ...editProcedure,
                            fee: event.target.value,
                          })
                        : setNewProcedure({
                            ...newProcedure,
                            fee: event.target.value,
                          });
                    }}
                  />
                  <p className="ml-3">Time</p>
                  <input
                    type="number"
                    className="ml-6 md:w-46 font-bold w-40 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                    value={editMode ? editProcedure.time : newProcedure.time}
                    onChange={(event) => {
                      editMode
                        ? setEditProcedure({
                            ...editProcedure,
                            time: event.target.value,
                          })
                        : setNewProcedure({
                            ...newProcedure,
                            time: event.target.value,
                          });
                    }}
                  />
                </label>
              </div>
            </form>
            {procedures.length > 0 && (
              <div className="mt-8">
                <h2 className="text-lg font-font-2 font-bold text-center">
                  PROCEDURE LIST
                </h2>
                <div className="mt-4 bg-gray-800 p-4 px-8 grid grid-cols-3 gap-x-8 gap-y-4">
                  {procedures.map((procedure) => (
                    <button
                      key={procedure.id}
                      className="bg-background-2 text-text-5 p-2"
                      onClick={() => setEditProcedure(procedure)}>
                      {procedure.type} : {procedure.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <Loading message={"Loading Procedure Details"} ready={ready} />
    </>
  );
}
