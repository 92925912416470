import { React } from "react";
import { NavLink } from "react-router-dom";

const email = localStorage.getItem('email');
export default function AdminSidebar() {
  return (
    <aside className="w-52 hidden lg:flex flex-col border-r-2 border-background-2 font-font-2 font-bold tracking-wide px-10">
      <ul className="mt-16 grid gap-10 text-text-2">
        <li>
          <NavLink exact to="/admin/profile" activeClassName="text-accent-1">
            Profile
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/admin/schedule" activeClassName="text-accent-1">
            Schedule
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/procedure" activeClassName="text-accent-1">
            Procedure
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/medication" activeClassName="text-accent-1">
            Medication
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/products" activeClassName="text-accent-1">
            Products
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/payment" activeClassName="text-accent-1">
            Payment
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/documents" activeClassName="text-accent-1">
            Documents
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/agreements" activeClassName="text-accent-1">
            Agreements
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/support" activeClassName="text-accent-1">
            Support
          </NavLink>
        </li>

        <li>
          <NavLink to="/admin/training" activeClassName="text-accent-1">
            Training
          </NavLink>
        </li>

        <li>
          <NavLink to="/admin/notifications" activeClassName="text-accent-1">
            Notifications
          </NavLink>
        </li>

        <li>
          <NavLink to="/admin/support-tickets" activeClassName="text-accent-1">
            Support Tickets
          </NavLink>
        </li>

        {/* {email && ['admin@gmail.com'].includes(email) && 
          <li>
            <NavLink to="/admin/control-panel" activeClassName="text-accent-1">
              Control Panel
            </NavLink>
          </li>
        } */}
      </ul>
    </aside>
  );
}
