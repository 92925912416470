import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from "../assets/images/logo.png";
import { useHistory, useParams } from 'react-router-dom';
import Loading from "../views/Loading";

const AdminAccessAccount = (props) => {
	const [message, setMessage] = useState("");
	const [ready, setReady] = useState(false);
	const history = useHistory();

	const { access_token, expires_at } = useParams();

	useEffect(() => {
		if(!access_token || !expires_at){
			setMessage("Something Went Wrong! Please Try Again.");
			setReady(true);
		}
		else{
			setTimeout(() => {
				props.setLoggedIn(true);
				localStorage.setItem('access_token', access_token);
				localStorage.setItem('expires_at', expires_at);

				history.push('/');
			},1000);
		}
	}, []);

	return (
		<>
			<main
				className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
					ready ? "" : "hidden"
				  }`}
			>
				<nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
					<NavLink to="/">
						<img className="h-20" src={Logo} alt="GRO Track" />
					</NavLink>
					<h1 className="mx-auto mt-4 text-3xl text-text-2 font-bold" style={{ transform: `translate(-40px)` }}>
						Access Account
					</h1>
					{/* <button onClick={(e) => {setDetails()}}>Access Account</button> */}
				</nav>
				{message && <p className="mt-8 text-center text-red-600">{message}</p>}
			</main>
			<Loading message="Accessing Account" ready={ready} />
		</>
	)
}

export default AdminAccessAccount