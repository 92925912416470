import { React, useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PresentationNavbar from '../components/PresentationNavbar';

const size = 10;

const createHairThicknessAnalysis = () => {
  const entries = 3;
  let id = 1;
  const areas = ['Front', 'Crown', 'Vertex', 'Back', 'Right', 'Left'];
  const imgs = [
    'https://ik.imagekit.io/visceailxwt/img4_tkLST_W5y.PNG',
    'https://ik.imagekit.io/visceailxwt/img5_vQkZrhj_H3.PNG',
  ];
  const hairAnalysis = [];
  for (let i = 0; i < entries; i++) {
    for (let j = 0; j < areas.length; j++) {
      const thickness = [];
      for (let t = 0; t < size; t++) {
        thickness.push(Number((Math.random() * 0.02 + 0.01).toFixed(3)));
      }
      hairAnalysis.push({
        id: id++,
        area: areas[j],
        date: `${17 + i}-11-2020`,
        img: imgs[(Math.random() * imgs.length).toFixed()],
        thickness: thickness,
      });
    }
  }
  return hairAnalysis;
};

export default function HairThicknessTracking() {
  const { id } = useParams();

  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groTrackKey, setGroTrackKey] = useState(false);
  const [hairThicknessAnalysis, setHairThicknessAnalysis] = useState(null);
  const [area, setArea] = useState('Front');
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);

  const currentAnalysis = () => {
    return hairThicknessAnalysis.filter(analysis => {
      return analysis.area === area;
    });
  };

  const difference = (n1, n2) => {
    return (((n2 - n1) * 100) / n1).toFixed(0);
  };

  const average = numbers => {
    return (numbers.reduce((a, b) => a + b, 0) / numbers.length).toFixed(3);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setGroTrackKey(Math.random() < 0.5);
          setHairThicknessAnalysis(createHairThicknessAnalysis());
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={6} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section>
          <h1 className="mt-8 text-lg text-center text-text-4">
            {`${firstName} ${lastName}`}
          </h1>
          {hairThicknessAnalysis && (
            <>
              <div className="mt-2 px-8 flex justify-between">
                <NavLink
                  exact
                  to={`/patient/presentation/gro-tracker/hair-density-tracking/${id}`}
                  activeClassName="text-text-2"
                >
                  Hair Density Tracking
                </NavLink>
                <div className="flex items-center">
                  <button
                    className="focus:outline-none"
                    onClick={() => {
                      setIndex1(Math.max(0, index1 - 1));
                    }}
                  >
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke="#bfc5ca"
                      stroke-width="1.25"
                    >
                      <path
                        fill="#bfc5ca"
                        d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                      />
                    </svg>
                  </button>
                  <h2 className="mx-2">{currentAnalysis()[index1].date}</h2>
                  <button
                    className="focus:outline-none"
                    onClick={() => {
                      setIndex1(
                        Math.min(currentAnalysis().length - 1, index1 + 1)
                      );
                    }}
                  >
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke="#bfc5ca"
                      stroke-width="1.25"
                    >
                      <path
                        fill=" #bfc5ca"
                        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                      />
                    </svg>
                  </button>
                </div>
                <NavLink
                  exact
                  to={`/patient/presentation/gro-tracker/hair-thickness-tracking/${id}`}
                  activeClassName="text-text-2"
                >
                  Hair Thickness Tracking
                </NavLink>
              </div>

              <div
                className="p-6 mt-4 bg-background-5 overflow-hidden"
                style={{ height: 'var(--height-2)' }}
              >
                <div className="flex">
                  <div className="md:absolute flex items-center">
                    {groTrackKey ? (
                      <svg
                        className="h-10 w-10 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="h-10 w-10 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                        />
                      </svg>
                    )}
                    <p className="ml-2 font-normal text-sm">GroTrack Key</p>
                  </div>
                  <div className="mt-6 flex items-center justify-center mx-auto font-font-2 text-text-8">
                    <button
                      className={`${
                        area === 'Front' && 'text-text-7'
                      } focus:outline-none mx-2`}
                      onClick={() => {
                        setArea('Front');
                      }}
                    >
                      Front
                    </button>
                    <button
                      className={`${
                        area === 'Crown' && 'text-text-7'
                      } focus:outline-none mx-2`}
                      onClick={() => {
                        setArea('Crown');
                      }}
                    >
                      Crown
                    </button>
                    <button
                      className={`${
                        area === 'Vertex' && 'text-text-7'
                      } focus:outline-none mx-2`}
                      onClick={() => {
                        setArea('Vertex');
                      }}
                    >
                      Vertex
                    </button>
                    <button
                      className={`${
                        area === 'Back' && 'text-text-7'
                      } focus:outline-none mx-2`}
                      onClick={() => {
                        setArea('Back');
                      }}
                    >
                      Back
                    </button>
                    <button
                      className={`${
                        area === 'Left' && 'text-text-7'
                      } focus:outline-none mx-2`}
                      onClick={() => {
                        setArea('Left');
                      }}
                    >
                      Left
                    </button>
                    <button
                      className={`${
                        area === 'Right' && 'text-text-7'
                      } focus:outline-none mx-2`}
                      onClick={() => {
                        setArea('Right');
                      }}
                    >
                      Right
                    </button>
                  </div>
                </div>
                {currentAnalysis().length > 0 ? (
                  <div className="mt-6 flex items-center justify-evenly">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center">
                        <button
                          className="focus:outline-none"
                          onClick={() => {
                            setIndex1(Math.max(0, index1 - 1));
                          }}
                        >
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                          >
                            <path
                              fill="#bfc5ca"
                              d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                            />
                          </svg>
                        </button>
                        <h2 className="mx-2">
                          {currentAnalysis()[index1].date}
                        </h2>
                        <button
                          className="focus:outline-none"
                          onClick={() => {
                            setIndex1(
                              Math.min(currentAnalysis().length - 1, index1 + 1)
                            );
                          }}
                        >
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                          >
                            <path
                              fill=" #bfc5ca"
                              d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                            />
                          </svg>
                        </button>
                      </div>
                      <img
                        className="mt-2 w-56"
                        src={currentAnalysis()[index1].img}
                        alt="Hair Analysis1"
                      />
                    </div>
                    <div className="text-center font-font-2 font-normal text-text-2 text-sm">
                      <div className="mb-4 flex items-center">
                        <p>Average</p>
                        <div className="mx-2 flex p-1 border-2 border-text-2 items-baseline">
                          <p>{average(currentAnalysis()[index1].thickness)}</p>
                          <p>&nbsp;/&nbsp;</p>
                          <p>{average(currentAnalysis()[index2].thickness)}</p>
                        </div>
                        <p
                          className={
                            difference(
                              average(currentAnalysis()[index1].thickness),
                              average(currentAnalysis()[index2].thickness)
                            ) < 0
                              ? 'text-button-3'
                              : 'text-accent-1'
                          }
                        >
                          {difference(
                            average(currentAnalysis()[index1].thickness),
                            average(currentAnalysis()[index2].thickness)
                          )}
                          %
                          <strong className="ml-1 text-2xl">
                            {difference(
                              average(currentAnalysis()[index1].thickness),
                              average(currentAnalysis()[index2].thickness)
                            ) < 0
                              ? '↓'
                              : '↑'}
                          </strong>
                        </p>
                      </div>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => {
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-center"
                          >
                            <div className="mx-2 flex p-1 border-2 border-text-2 items-baseline">
                              <p>
                                {currentAnalysis()[index1].thickness[index]}
                              </p>
                              <p>&nbsp;/&nbsp;</p>
                              <p>
                                {currentAnalysis()[index2].thickness[index]}
                              </p>
                            </div>
                            <p
                              className={
                                difference(
                                  currentAnalysis()[index1].thickness[index],
                                  currentAnalysis()[index2].thickness[index]
                                ) < 0
                                  ? 'text-button-3'
                                  : 'text-accent-1'
                              }
                            >
                              {difference(
                                currentAnalysis()[index1].thickness[index],
                                currentAnalysis()[index2].thickness[index]
                              )}
                              %
                              <strong className="ml-1 text-2xl">
                                {difference(
                                  currentAnalysis()[index1].thickness[index],
                                  currentAnalysis()[index2].thickness[index]
                                ) < 0
                                  ? '↓'
                                  : '↑'}
                              </strong>
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex flex-col items-center">
                      <div className="flex items-center">
                        <button
                          className="focus:outline-none"
                          onClick={() => {
                            setIndex2(Math.max(0, index2 - 1));
                          }}
                        >
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                          >
                            <path
                              fill="#bfc5ca"
                              d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                            />
                          </svg>
                        </button>
                        <h2 className="mx-2">
                          {currentAnalysis()[index2].date}
                        </h2>
                        <button
                          className="focus:outline-none"
                          onClick={() => {
                            setIndex2(
                              Math.min(currentAnalysis().length - 1, index2 + 1)
                            );
                          }}
                        >
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                          >
                            <path
                              fill=" #bfc5ca"
                              d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                            />
                          </svg>
                        </button>
                      </div>
                      <img
                        className="mt-2 w-56"
                        src={currentAnalysis()[index2].img}
                        alt="Hair Analysis2"
                      />
                    </div>
                  </div>
                ) : (
                  <h2 className="mt-4 text-center font-font-2">
                    No Analysis Present
                  </h2>
                )}
              </div>
            </>
          )}
          <PresentationNavbar id={id} tab={4} />
        </section>
      </main>
      <Loading message={'Loading Gro Tracker'} ready={ready} />
    </>
  );
}
