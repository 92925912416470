import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { Link, useParams, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { BsFlagFill } from 'react-icons/bs';


const ImageLoader = ({ img, onDoubleClick, secondaryImg, showBlackTint, bordered, showMaskAssessment, showFlagIcon = true, maxHeight = "419px", setReady, isPopup = false }) => {
	// const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('');
	const [secondaryImgMessage, setSecondaryImgMessage] = useState('');
	const [showSecondaryImage, setShowSecondaryImage] = useState(true);
	const location = useLocation()
	const isDevMode = new URLSearchParams(location.search).get("mode") === 'dev';
	const { patient_id, analysis_id, graphType } = useParams();

	const globalMatch = useRouteMatch("/patient/:patient_id/consult/:analysis_id/global/:subType");
	const closeupMatch = useRouteMatch("/patient/:patient_id/consult/:analysis_id/closeup/:subType");

	const [showFlagModal, setShowFlagModal] = useState(false);
	const [description, setDescription] = useState("");
	const { pathname } = useLocation();
	const Swal = require('sweetalert2');

	useEffect(() => {
		// setLoading(true);
		setMessage(getMessage(img));
		setSecondaryImgMessage(getMessage(secondaryImg));
	}, [img, secondaryImg]);

	const getMessage = (img) => {
		if (!img) {
			return 'No image found';
			// setLoading(false);
		} else if (!img.image_path || img.image_path === '0') {
			return <p className='mb-2'>Image is being processed</p>;
			// setLoading(false);
		} else if (img.status === 'invalidated') {
			return <ShowInvalidReason img={img} />;
			// setLoading(false);
		} else if (img.status === 'invalid') {
			return <p className="text-center text-lg mb-2" style={{ color: "#EF5656" }}>Pending validation</p>;
			// setLoading(false);
		} else if (img.status === 'approved') {
			return <p className="text-center text-turquoise-1 text-lg mb-2">Validated</p>;
		} else {
			return "";
		}
	}

	// const handleImageLoaded = () => {
	// 	setLoading(false);
	// };

	const handleDoublieClick = () => {
		if (onDoubleClick) {
			onDoubleClick();
		}
	}

	const handleToggleSecondaryImage = () => {
		if (secondaryImg) {
			setShowSecondaryImage(prev => !prev);
		}
	}

	const createSupportTicket = (ticket) => {
		setShowFlagModal(false);
		setReady(false);
		fetch(
			`${process.env.REACT_APP_API}/tickets`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				},
				body: JSON.stringify(ticket),
			}
		).then(response => response.json()).then(response => {
			Swal.fire({
				title: 'Success!',
				text: `Support ticket created successfully.`,
				icon: 'success',
				confirmButtonText: 'Ok'
			});
		}).catch((err)=>{
			alert('Something went wrong!');
		}).finally(() => setReady(true));
	}

	const reprocess = () => {
		setReady(false);
		fetch(`${process.env.REACT_APP_API}/hair_analysis/image/${img?.id}/reprocess`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					Swal.fire({
						title: 'Success!',
						text: `Successfully reprocessed`,
						icon: 'success',
						confirmButtonText: 'Ok'
					});
				}
			})
			.catch((error) => {
				console.error(error);
				setMessage("Some Error Occured. Please Try Again Later");
			})
			.finally(() => {
				setReady(true);
			});
	}

	return (
		<>
			<div className="relative flex flex-col items-center pt-7 w-full">
				{/* {loading && (
					<div className="absolute flex items-center justify-center w-16">
						<div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-300"></div>
					</div>
				)} */}
				{
					message && message !== 'No image found' && <p className='absolute top-0 z-50' style={{ transform: 'translate(0, -5px)' }}>{message}</p>
				}
				{
					!message && secondaryImgMessage !== 'No image found' && secondaryImgMessage && <p className='absolute top-0 z-50' style={{ transform: 'translate(0, -5px)' }}>{secondaryImgMessage}</p>
				}
				{message && message === 'No image found' ? (
					<div className='hidden sm:flex justify-center h-60 xxs:h-64 xs:h-80 sm:h-64 lg:h-80 xl:h-96' style={{ marginBottom: globalMatch || closeupMatch ? '74px' : '0px' }}>
						<p className="text-center text-gray-400 h-full">{message}</p>
					</div>
				) : (
					<div className='flex flex-col justify-center items-center'>
						<div className='relative' style={{ width: "fit-content" }}>
							{img?.image_path && showFlagIcon && <BsFlagFill className='w-6 h-6 p-1' style={{ background: 'black', position: 'absolute', top: 5, right: 5, color: '#fff', cursor: 'pointer', zIndex: '2' }} onClick={() => { setShowFlagModal(true) }} />}
							{showFlagModal ? (
								<>
									<div
										className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
									>
										<div className="relative w-auto my-6 mx-auto max-w-3xl" style={{ width: '480px' }}>
											{/*content*/}
											<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
												{/*body*/}
												<div className="relative p-6 flex-auto">
													<div className="text-lg text-center">Request for validation</div>
													<div className="mt-6">
														<ImageLoader img={img} secondaryImg={secondaryImg} showFlagIcon={false} maxHeight={"250px"} isPopup={true} />
													</div>
													<div className="mt-6">
														<div className="mb-3">Notes</div>
														<textarea value={description} onChange={(e) => setDescription(e.target.value)} className="border border-solid border-slate-200 p-3" style={{ width: '100%', height: '120px' }}></textarea>
													</div>
												</div>
												{/*footer*/}
												<div className="flex items-center justify-between px-6 py-3 border-t border-solid border-slate-200 rounded-b">
													<button
														className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
														type="button"
														onClick={() => {
															createSupportTicket({ image_id: img?.id, description: description });
														}}
													>
														Submit
													</button>
													<button
														className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
														type="button"
														onClick={() => setShowFlagModal(false)}
													>
														Cancel
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
								</>
							) : null}
							<img
								src={`${img?.image_path}?width=312`}
								alt={`Paint ${img?.paint} - ${img?.mainType} ${img?.subType}`}
								className="cursor-pointer box-border w-auto h-60 xxs:h-64 xs:h-80 sm:h-64 lg:h-80 xl:h-96"
								// onLoad={handleImageLoaded}
								// style={{ display: loading ? 'none' : 'block',  maxHeight: '419px'}}
								style={{ border: bordered ? '1px solid #6c6f75' : 'none' }}
								onDoubleClick={handleDoublieClick}
								onClick={handleToggleSecondaryImage}
							/>
							{img?.status !== 'invalid' && secondaryImg && secondaryImg?.image_path && secondaryImg?.image_path !== '0' && showSecondaryImage && (
								<img
									src={`${secondaryImg?.image_path}?width=312`}
									className={` cursor-pointer box-border ${showBlackTint ? `bg-black bg-opacity-50` : ''}`}
									style={{
										position: 'absolute',
										top: 0,
										left: graphType === 'hair_thinning' || showMaskAssessment ?
											img?.ml_data?.x_min_assessment ? img?.ml_data?.x_min_assessment * 100 + '%' : 'auto' :
											img?.scalp_position?.x_start ? img?.scalp_position?.x_start * 100 + '%' : 'auto'
										,
										height: '100%',
										objectFit: 'cover',
									}}
									onClick={handleToggleSecondaryImage}
									onDoubleClick={handleDoublieClick}
								/>
							)}
						</div>
						{isDevMode && img.ml_data && <div className='mt-6'>
							{Object.keys(img.ml_data).map((item, index) => {
								return (
									<h1 key={index} className="capitalize mb-3 break-words  font-bold text-text-9 text-opacity-85 text-sm leading-5 opacity-80" style={{ letterSpacing: '1.6px' }}>
										{item.split("_").join(" ")} : {img?.ml_data?.[item]}
									</h1>
								)
							})}
						</div>}
						{isDevMode && (
							<div className='mt-6'>
								<button type="button" class="px-12 py-1 bg-background-23 font-bold tracking-wider rounded-lg text-text-3 cursor-pointer focus:outline-none" onClick={reprocess}>
									Reprocess
								</button>
							</div>
						)}
						{(globalMatch || closeupMatch) && (!isPopup) && (
							<div className='w-full text-center mt-5 hidden sm:block'>
								<Link to={`/patient/${patient_id}/consult/${analysis_id}/gro_reprot`} className="w-full block bg-transparent border border-solid py-2 lg:py-3 sm:px-0 lg:px-4 font-bold text-base xxs:text-lg font-sans tracking-wider leading-5 uppercase" style={{ color: '#D0D4D7', borderColor: 'rgba(246,247,250,0.4)' }}>Gro Reports</Link>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

const ShowInvalidReason = ({ img }) => {
	const [showPopup, setShowPopup] = useState(false);
	const componentRef = useRef(null);

	const closePopup = () => {
		setShowPopup(prev => !prev);
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setShowPopup(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<p className="text-center text-lg flex items-center gap-4 relative mb-2" style={{ color: "#EF5656" }} ref={componentRef}>
			Invalid

			<div className='relative'>
				<AiOutlineExclamationCircle className='inline text-white cursor-pointer' onClick={closePopup} />
				{
					showPopup && (
						window.innerWidth < 640 ? (
							<div className="absolute top-8 flex flex-col items-center mb-6 group-hover:flex left-1/2" style={{ transform: 'translate(-100%, 0)' }}>
								<div className="w-6 h-6 bg-white absolute right-0" style={{ transform: "rotate(45deg) translate(9px, -9px)" }}></div>
								<div className="relative z-10 p-2 w-max bg-white text-center rounded-lg" style={{ transform: 'translate(24px, 12px)' }}>
									{
										img.reason.map((reason, i) => (
											<p key={i} className='text-base font-bold leading-none text-black whitespace-nowrap'>{reason}</p>
										))
									}
								</div>
							</div>
						) : (
							<div className="absolute top-8 flex flex-col items-center mb-6 group-hover:flex left-1/2" style={{ transform: 'translate(-50%, 0)' }}>
								<div className="w-6 h-6 bg-white" style={{ transform: "rotate(45deg)" }}></div>
								<div className="relative z-10 p-2 w-max bg-white text-center rounded-lg" style={{ transform: 'translate(0, -12px)' }}>
									{
										img.reason.map((reason, i) => (
											<p key={i} className='text-base font-bold leading-none text-black whitespace-nowrap'>{reason}</p>
										))
									}
								</div>
							</div>
						)

					)
				}
			</div>


		</p>
	);
}

export default React.memo(ImageLoader);
