import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";

export default function AdminMedication() {
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);

  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: "",
    brand: "",
    volume: "",
    fee: "",
  });
  const [editProduct, setEditProduct] = useState(null);

  const fetchProducts = () => {
    fetch(`${process.env.REACT_APP_API}/clinic/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setProducts(response.products);
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
      })
      .finally(() => setReady(true));
  };

  const handleSubmit = () => {
    if (editMode) {
      setReady(false);
      fetch(
        `${process.env.REACT_APP_API}/clinic/medications/${editProduct.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(editProduct),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setEditMode(false);
            setEditProduct(null);
            setSuccess("Products saved successfully");
            fetchProducts();
          } else {
            setMessage(response.message);
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    } else {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/clinic/products`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(newProduct),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setNewProduct({
              name: "",
              brand: "",
              volume: "",
              fee: "",
            });
            setSuccess("New product added successfully");
            fetchProducts();
          } else {
            setMessage(response.message);
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (editProduct) setEditMode(true);
  }, [editProduct]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}>
        <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
          <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
            PACIFIC HAIR CENTER
          </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex">
          <AdminSidebar />
          <div className="mx-auto">
            <form
              className="mt-4 flex flex-col"
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}>
              <div className="flex justify-between items-center">
                <button
                  type="button"
                  className={`px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none ${!editMode && "hidden"}`}
                  onClick={() => {
                    setEditMode(false);
                    setEditProduct(null);
                  }}>
                  Cancel
                </button>
                <p className="mx-24 text-text-2">SET UP PRODUCT</p>
                <button
                  className="px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none">
                  {editMode ? "SAVE" : "ADD"}
                </button>
              </div>
              <div className="mx-auto mt-10 flex flex-col items-end">
                <div
                  className={`w-full md:w-96 px-2 ${
                    editMode ? "" : "hidden"
                  }`}></div>
                <label className="mt-4 flex items-center">
                  <p className="ml-2">Product</p>
                  <input
                    type="text"
                    className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={editMode ? editProduct.name : newProduct.name}
                    onChange={(event) => {
                      editMode
                        ? setEditProduct({
                            ...editProduct,
                            name: event.target.value,
                          })
                        : setNewProduct({
                            ...newProduct,
                            name: event.target.value,
                          });
                    }}
                  />
                </label>
                <label className="mt-4 flex items-center">
                  <p className="ml-2">Brand Name</p>
                  <input
                    type="text"
                    className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={editMode ? editProduct.brand : newProduct.brand}
                    onChange={(event) => {
                      editMode
                        ? setEditProduct({
                            ...editProduct,
                            brand: event.target.value,
                          })
                        : setNewProduct({
                            ...newProduct,
                            brand: event.target.value,
                          });
                    }}
                  />
                </label>
                <div className="mt-4 flex">
                  <p className="ml-2 mt-1">Volume</p>
                  <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2 mb-4">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                        value={
                          editMode ? editProduct.volume : newProduct.volume
                        }
                        onChange={(event) => {
                          editMode
                            ? setEditProduct({
                                ...editProduct,
                                volume: event.target.value,
                              })
                            : setNewProduct({
                                ...newProduct,
                                volume: event.target.value,
                              });
                        }}
                      />
                    </label>
                  </div>
                </div>
                <label className="ml-16 flex items-center self-start">
                  <p className="ml-2 mr-1">Fee</p>
                  <input
                    type="number"
                    className="ml-6 md:w-46 font-bold w-40 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                    value={editMode ? editProduct.fee : newProduct.fee}
                    onChange={(event) => {
                      editMode
                        ? setEditProduct({
                            ...editProduct,
                            fee: event.target.value,
                          })
                        : setNewProduct({
                            ...newProduct,
                            fee: event.target.value,
                          });
                    }}
                  />
                </label>
              </div>
            </form>
            {products.length > 0 && (
              <div className="mt-8">
                <h2 className="text-lg font-font-2 font-bold text-center">
                  PRODUCT LIST
                </h2>
                <div className="mt-4 bg-gray-800 p-4 px-8 grid grid-cols-3 gap-x-8 gap-y-4">
                  {products.map((product) => (
                    <button
                      key={product.id}
                      className="bg-background-2 text-text-5 p-2 focus:outline-none"
                      onClick={() => setEditProduct(product)}>
                      {product.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <Loading message={"Loading Medication Details"} ready={ready} />
    </>
  );
}
