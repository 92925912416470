import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { FiSend } from "react-icons/fi";

import Logo from "../assets/images/logo.png";
import Loading from "./Loading";

const SupportTicket = () => {
    const [message, setMessage] = useState("");
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [image, setImage] = useState({});
    const [showImage, setShowImage] = useState("image");
    const [mlImage, setMlImage] = useState({});
    const [ticket, setTicket] = useState({});
    const Swal = require('sweetalert2')

    const { id } = useParams();

    const getInvalidImage = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/tickets/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setTicket(response.ticket);
                    setImage(response.ticket.image);
                    setMlImage(response.ticket.ml_image);

                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
            }).finally(() => {
                setReady(true);
            });
    }

    const getComments = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/ticket-comments?ticket_id=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setComments(response.comments.data?.reverse());
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
            }).finally(() => {
                setReady(true);
            });
    }

    const addComment = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/ticket-comments`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify({
                ticket_id: id,
                text: comment,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    getComments();
                } else {
                    setMessage(response.message);
                    setReady(true);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            }).finally(() => {
                setComment('');
            });
    }

    useEffect(() => {
        if (id) {
            getInvalidImage();
            getComments();
        }
    }, [id]);

    const displayImage = (image) => {
        if (image.mainType === 'closeup') {
            var x = image?.scalp_position?.x_start;
            var y = image?.scalp_position?.x_end;

            return (
                <div className="flex items-center justify-center h-96 mb-8">
                    <div className="mx-auto h-96 w-full">
                        <div className="relative flex justify-center h-96 w-full">
                            {showImage === 'ai' && mlImage?.image_path ? <img src={mlImage.image_path} className="mb-4 h-96 cursor-pointer absolute z-10" alt="ML-img" style={{ left: x ? (x * 100) + '%' : undefined }} onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} /> : ""}
                            <img src={image.image_path} className="w-full mb-4 h-96 cursor-pointer absolute object-cover z-0" alt="Normal-img" onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} />
                        </div>
                    </div>
                </div>
            );
        }
        var x = image?.scalp_position?.x_start;
        // var y = image?.scalp_position?.x_end;
        return (
            <div className="flex items-center justify-center h-96 mb-8">
                <div className="mx-auto h-96 w-full">
                    <div className="relative flex justify-center h-96 w-full">
                        {showImage === 'ai' && mlImage?.image_path ? <img src={mlImage.image_path} className="mb-4 h-96 cursor-pointer absolute z-10" alt="ML-img" style={{ left: x ? (x * 100) + '%' : undefined }} onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} /> : ""}
                        <img src={image.image_path} className="w-full mb-4 h-96 cursor-pointer absolute object-cover z-0" alt="Normal-img" onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} />
                    </div>
                </div>
            </div>
        );
    }

    const printStatus = () => {
        if (ticket.status) {
            if (ticket.status === 'invalid') {
                return 'review pending';
            }
            return ticket.status;
        }
    }

    return (
        <>
            <main
                className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${ready ? "" : "hidden"
                    }`}
            >
                <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
                    <NavLink to="/">
                        <img className="h-20" src={Logo} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto mt-4 text-3xl text-text-2 font-bold text-white" style={{ transform: `translate(-40px)` }}>
                        Support Tickets
                    </h1>
                </nav>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && (
                    <p className="mt-8 text-center text-green-600">{success}</p>
                )}
                <section className="pt-10">
                    <div className="text-center py-3 my-auto">
                        <h2 className="text-xl text-gray-300 mb-3">{ticket?.patient_name} ({ticket?.clinic?.name})</h2>
                        <h5 className="mb-2 mb-3 text-gray-400">{dayjs(ticket?.updated_at).format("MM-DD-YY")}</h5>
                        <h5 className="mb-2 mb-3 text-gray-400">{ticket?.image?.mainType}/{ticket?.image?.subType}</h5>
                        <h2 className="text-xl text-gray-300 capitalize mb-3">Status: {printStatus()}</h2>
                        {image.status === 'invalidated' &&
                            <p className="mb-2 mb-3 text-gray-400 font-light">
                                {image.reason.join(', ')}
                            </p>
                        }
                    </div>
                    <div className={`text-center py-3 m-auto`} style={{ width: "292px" }}>
                        {displayImage(image)}
                    </div>
                    <div className="w-4/5 lg:w-3/5 m-auto text-center mb-11">
                        <h2 className="text-xl text-gray-300 capitalize mb-3">Description</h2>
                        <p>{ticket?.description}</p>
                    </div>
                    <div className="w-4/5 lg:w-3/5 m-auto relative">
                        <div className="overflow-y-scroll pr-10 mb-4" style={{ height: 'calc(100vh - 235px)' }}>
                            {comments?.map((comment, index) => {
                                return (
                                    <div key={index} className="bg-background-16 p-2 rounded-lg my-2 text-sm">
                                        <div className="flex justify-between border-b border-background-2 pb-2">
                                            <div>{comment?.clinic?.name}</div>
                                            <div>{dayjs(comment?.created_at).format('DD/MM/YY')}</div>
                                        </div>
                                        <p className="py-2">{comment?.text}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex w-full justify-between">
                            <input className="bg-background-1 border-2 border-background-2 flex-1 px-2" value={comment} onChange={(e) => setComment(e.target.value)} />
                            <div className="rounded-full border-2 border-background-2 p-2 ml-2 cursor-pointer" onClick={addComment}>
                                <FiSend />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Loading message={"Loading Details"} ready={ready} />
        </>
    );
};

export default SupportTicket;