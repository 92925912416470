import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import CrossGrey from "../../assets/images/CrossGrey.svg";
import dayjs from 'dayjs';
import ShareGrey from '../../assets/images/ShareGrey.svg'
import PrintGrey from '../../assets/images/PrintGrey.svg'
import DownloadGrey from '../../assets/images/DownloadGrey.svg'

const GroReportView = ({ analyses, setReady, firstName, lastName }) => {
    const { patient_id, analysis_id } = useParams();
    const analysis_id_int = parseInt(analysis_id);

    const [pdf_blob, setPdfBlob] = useState('');
    const [showDropdown, setShowDropdown] = useState(false)

    useEffect(() => {
        fetchReport();
    }, [patient_id, analysis_id, analyses]);

    const fetchReport = () => {
        setReady(false);
        if (analyses[analysis_id_int]) {

            fetch(`${process.env.REACT_APP_API}/hair_analysis/${analyses[analysis_id_int].id}/gro_report`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then((response) => response.blob())
                .then((blob) => {

                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        blob,
                    );
                    setPdfBlob(url);

                }).finally(() => {
                    setReady(true);
                });
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = pdf_blob;
        link.setAttribute(
            'download',
            `${firstName} ${lastName} ${dayjs
                .unix(analyses[analysis_id_int].created_at)
                .format('MM-DD-YY')}.pdf`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const handlePrint = () => {
        const printWindow = window.open(pdf_blob, '_blank', 'fullscreen=yes');
        printWindow.print();
    }

    const handleToggleDropdown = () => {
        setShowDropdown(prev => !prev)
    }
    return (
        <div className='py-4 sm:py-0 px-2 md:px-4 lg:px-10 h-full flex flex-col gap-4' style={{ flexGrow: 1 }}>
            <div className='absolute top-5 right-5'>
                <Link to={`/patient/${patient_id}/consult/${analysis_id}/global/crown/hair_thinning`}>
                    <img src={CrossGrey} className='h-5 w-5' />
                </Link>
            </div>
            {/* <div className='mb-8'>
                <h1 className='text-center text-xl font-bold text-20 leading-21 font-normal font-arial tracking-widest text-white opacity-70'>GRO report</h1>
            </div> */}
            <div className='flex flex-col sm:flex-row gap-6 sm:gap-3 md:gap-6 lg:gap-12 xl:gap-16' style={{ flexGrow: 1 }}>
                <div className='w-1/6 hidden sm:flex flex-col items-center'>
                    {
                        analyses.map((analysis, index) => {
                            return (
                                <p className='mb-3'>
                                    <Link to={`/patient/${patient_id}/consult/${index}/gro_reprot`} className={`font-bold text-base leading-5 font-roboto tracking-widest text-white ${index === analysis_id_int ? 'opacity-80' : 'opacity-40'} `}>
                                        {
                                            dayjs
                                                .unix(analysis.created_at)
                                                .format("MM-DD-YY")
                                        }
                                    </Link>
                                </p>
                            )
                        })
                    }
                </div>
                <div className='w-full sm:w-4/6 flex order-2 relative h-auto' style={{ flexGrow: 1 }}>
                    {pdf_blob ? <iframe title="Gro Report" src={`${pdf_blob}#toolbar=0&navpanes=0`} width="100%" style={{ position: 'absolute', top: '0', height: '100%' }}></iframe> : ''}
                </div>
                <div className='w-full sm:w-1/6 order-1 sm:order-3 flex justify-between items-center sm:block'>
                    <div className='block sm:hidden relative'>
                        <p className='font-bold text-base font-roboto tracking-widest text-text-2 opacity-80 cursor-pointer' onClick={handleToggleDropdown}>
                            {analyses?.find(item => analyses.indexOf(item) === analysis_id_int) && dayjs
                                .unix(analyses?.find(item => analyses.indexOf(item) === analysis_id_int).created_at)
                                .format("MM-DD-YY")}
                        </p>
                        {showDropdown && (
                            <div className="absolute top-8 left-1/2  flex flex-col items-center mb-6 group-hover:flex" style={{ transform: 'translate(-50%)' }}>
                                {
                                    analyses.map((analysis, index) => {
                                        return (
                                            <p className={`${index === analysis_id_int ? 'bg-gray-300' : 'bg-white'} text-black p-1 whitespace-nowrap border-b border-gray-200 hover:bg-gray-300`}>
                                                <Link to={`/patient/${patient_id}/consult/${index}/gro_reprot`} className={`whitespace-nowrap font-bold text-base leading-5 font-roboto tracking-widest`} onClick={() => { setShowDropdown(false) }}>
                                                    {
                                                        dayjs
                                                            .unix(analysis.created_at)
                                                            .format("MM-DD-YY")
                                                    }
                                                </Link>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
                    <div className='flex gap-3 sm:gap-1 md:gap-3 justify-center'>
                        <img className='cursor-pointer' src={ShareGrey} />
                        <img className='cursor-pointer' src={DownloadGrey} onClick={handleDownload} />
                        <img className='cursor-pointer' src={PrintGrey} onClick={handlePrint} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(GroReportView);