import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/logo.png';


export default function Dashboard() {
  return (
    <main className="flex flex-col p-4 font-normal font-font-2">
      <section className="container m-auto flex flex-col">
        <NavLink to="/" className="mx-auto">
          <img className="h-32" src={Logo} alt="GRO Track" />
        </NavLink>
        <nav className="mt-16 grid grid-cols-4 gap-2">
          <figure className="flex flex-col items-center">
            <NavLink
              to="/patient/new"
              className="inline-block p-6 bg-button-1 rounded-full"
            >
              <svg
                className="h-12 w-12"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
              </svg>
            </NavLink>
            <figcaption className="mt-6 text-center">New Patient</figcaption>
          </figure>
          <figure className="flex flex-col items-center">
            <NavLink
              to="/patient/existing"
              className="inline-block p-6 bg-button-2 rounded-full"
            >
              <svg
                className="h-12 w-12"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
              </svg>
            </NavLink>
            <figcaption className="mt-6 text-center">
              Existing Patient
            </figcaption>
          </figure>
          <figure className="flex flex-col items-center">
            <NavLink
              to="/patient/referrals"
              className="inline-block p-6 bg-background-11 rounded-full"
            >
              <svg
                className="h-12 w-12"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
              </svg>
            </NavLink>
            <figcaption className="mt-6 text-center">
              Patient Referrals
            </figcaption>
          </figure>
          <figure className="flex flex-col items-center">
            <NavLink
              to="/admin/profile"
              className="inline-block p-6 bg-button-3 rounded-full"
            >
              <svg
                className="h-12 w-12"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" />
              </svg>
            </NavLink>
            <figcaption className="mt-6 text-center">Admin</figcaption>
          </figure>
        </nav>
      </section>
      <footer>
        <div className="pb-8 text-center">
          <button
            className="bg-transparent text-gray-500 font-semibold py-2 px-8 border border-gray-500 rounded-lg"
            onClick={() => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('expires_at');
              window.location.href = '/';
            }}
          >
            Sign Out
          </button>
        </div>
        <div className="py-4 border-t-2 border-accent-2 text-center">
          GRO technologies
        </div>
      </footer>
    </main>
  );
}
