import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Loading from './Loading'
import Logo from "../assets/images/logo.png";

const Welcome = () => {
    const [ready, setReady] = useState(false);
    const [username, setUsername] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/admin/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.success) {
              setUsername(response.adminProfile.clinic.name)
            } else {
              setMessage(response.message);
            }
            setReady(true);
          })
          .catch((error) => {
            console.error(error);
            setMessage("Some Error Occured. Please Try Again Later");
            setReady(true);
          });
      }, []);

    return (
        <>
            <main
                className={`container mx-auto flex flex-col p-4 text-text-2 ${
                ready ? "" : "hidden"
                }`}
            >
                <nav className="flex items-center pb-6 border-b-2 border-background-2">
                    <NavLink to="/">
                        <img className="h-20" src={Logo} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
                        {username && (username) }
                    </h1>
                </nav>
                
                <div className='flex flex-col items-center justify-center' style={{ minHeight: 'calc(100vh - 138px)' }}>
                    <h1 className='text-3xl text-gray-300 my-12'>Welcome to Grotrack</h1>
                    <Link to={`admin/training`} className='text-3xl text-gray-300 my-12'>Start GroTrack online tranining course</Link>
                </div>
            </main>
            <Loading message="Loading" ready={ready} />
        </>
    )
}

export default Welcome