import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Loading from "../views/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default function PatientSearch() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(true);
  const [searchResults, setSearchResults] = useState(null);

  const isPatientValid = () => {
    return true;
  };

  const searchPatient = () => {
    if (isPatientValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/patient/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          dob: dob && dob !== "" ? moment(dob).format("YYYY-MM-DD") : "",
          phone: phone.trim(),
          email: email.trim().toLowerCase(),
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setSearchResults(response.patients);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  return (
    <>
      <main
        className={`container mx-auto flex flex-col p-4 text-text-2 ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex pb-6 border-b-2 border-background-2">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
        </nav>
        <h1 className="mt-16 text-center text-xl">SEARCH PATIENT</h1>
        {message && <p className="mt-4 text-center text-red-600">{message}</p>}
        {searchResults ? (
          <>
            {searchResults.length > 0 ? (
              <section className="mt-16 grid gap-4 justify-center font-font-2">
                {searchResults.map((patient) => {
                  return (
                    <div key={patient.id} className="flex items-end">
                      <p className="w-96 p-2 bg-background-2 text-text-7 text-center rounded-sm flex items-baseline justify-evenly">
                        <span className="truncate">
                          {patient.firstName} {patient.lastName}
                        </span>
                        <span className="font-normal text-sm">
                          DOB: {patient.dob}
                        </span>
                      </p>
                      <NavLink
                        to={`/patient/${patient.id}/consult/0/global/crown/hair_thinning`}
                        className="ml-8 px-8 py-1 bg-accent-1 rounded-lg text-sm text-text-3 focus:outline-none"
                      >
                        SELECT
                      </NavLink>
                    </div>
                  );
                })}
              </section>
            ) : (
              <h1 className="mt-16">NO PATIENTS FOUND</h1>
            )}
            <button
              className="mt-16 mx-auto px-8 py-1 bg-accent-1 rounded-lg text-text-3 focus:outline-none"
              onClick={() => {
                setSearchResults(null);
              }}
            >
              SEARCH AGAIN
            </button>
          </>
        ) : (
          <form
            className="mx-auto mt-16 flex flex-col items-end"
            onSubmit={(event) => {
              event.preventDefault();
              searchPatient();
            }}
          >
            <label className="flex items-center">
              <p className="ml-2">First Name</p>
              <input
                type="text"
                className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </label>
            <label className="mt-4 flex items-center">
              <p className="ml-2">Last Name</p>
              <input
                type="text"
                className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </label>
            <label className="mt-4 ml-12 self-start flex items-center">
              <p className="ml-2.5">DOB</p>
              {/* <input
                type="date"
                
                className="ml-6 w-40 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                value={dob}
                onChange={(event) => {
                  setDOB(event.target.value);
                }}
              /> */}
              <DatePicker
                dateFormat="MM/dd/yyyy"
                className="ml-6 w-40 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                selected={Date.parse(dob)}
                onChange={(date) => {
                  setDOB(date);
                  console.log(date);
                }}
              />
            </label>
            <label className="mt-4 flex items-center">
              <p className="ml-2">Phone</p>
              <input
                type="text"
                className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
            </label>
            <label className="mt-4 flex items-center">
              <p className="ml-2">Email</p>
              <input
                type="text"
                className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </label>
            <input
              className="mt-16 px-8 py-1 bg-accent-1 rounded-lg text-text-3 cursor-pointer focus:outline-none"
              type="submit"
              value="SEARCH"
            />
          </form>
        )}
      </main>
      <Loading message="Searching For Patient" ready={ready} />
    </>
  );
}
