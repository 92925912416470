import { React } from 'react';
import { NavLink } from 'react-router-dom';

export default function PatientSidebar(props) {
  return (
    <aside className="w-56 pr-4 mr-4 hidden lg:flex flex-col border-r-2 border-background-7 font-font-2 text-lg tracking-wider">
      <ul className="mt-16 grid gap-10 text-text-2 mx-10">
        <li>
          <NavLink
            exact
            to={`/patient/account/profile/${props.id}`}
            activeClassName="text-accent-1"
          >
            Profile
          </NavLink>
        </li>
        <li>
          <ul>
            <li>
              <button
                className={
                  props.isHealthOpen
                    ? 'flex text-accent-1 focus:outline-none'
                    : 'flex font-font-1 font-weight-400 focus:outline-none'
                }
                onClick={() =>
                  props.setHealthDrop && props.setHealthDrop(!props.healthDrop)
                }
              >
                Health Info
                {props.healthDrop ? (
                  <svg
                    className=" ml-3 mt-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 30 30"
                  >
                    <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
                  </svg>
                ) : (
                  <svg
                    className=" ml-3 mt-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 30 30"
                  >
                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                  </svg>
                )}
              </button>
            </li>
            {props.healthDrop && (
              <>
                <li>
                  <NavLink
                    className="text-sm"
                    exact
                    to={`/patient/account/health-info/${props.id}`}
                    activeClassName="text-text-3"
                  >
                    General Info
                  </NavLink>
                </li>
                <li>
                  <ul>
                    <li>
                      <button
                        className={
                          props.isHealthHistoryOpen
                            ? 'flex text-text-3 text-sm focus:outline-none'
                            : 'flex font-font-1 text-sm focus:outline-none'
                        }
                        onClick={() =>
                          props.setHistoryDrop &&
                          props.setHistoryDrop(!props.historyDrop)
                        }
                      >
                        Health History
                        {props.historyDrop ? (
                          <svg
                            className=" ml-2 mt-1 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 30 30"
                          >
                            <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
                          </svg>
                        ) : (
                          <svg
                            className="ml-2 mt-1 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 30 30"
                          >
                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                          </svg>
                        )}
                      </button>
                    </li>
                    {props.historyDrop && (
                      <>
                        <li>
                          <NavLink
                            exact
                            to={`/patient/${props.id}/health_history_medication`}
                            className="mt-1 ml-2 text-sm"
                            activeClassName="text-text-3"
                          >
                            Medication
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact
                            to={`/patient/${props.id}/health_history_treatment`}
                            className="mt-1 ml-2 text-sm"
                            activeClassName="text-text-3"
                          >
                            Treatment
                          </NavLink>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              </>
            )}
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <button
                className={
                  props.isExamOpen
                    ? 'flex align-end text-accent-1 focus:outline-none'
                    : 'flex font-font-1 font-weight-400 align-end focus:outline-none'
                }
                onClick={() => {
                  props.setExamDrop && props.setExamDrop(!props.examDrop);
                }}
              >
                Exam
                {props.examDrop ? (
                  <svg
                    className=" ml-3 mt-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 30 30"
                  >
                    <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
                  </svg>
                ) : (
                  <svg
                    className=" ml-3 mt-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 30 30"
                  >
                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                  </svg>
                )}
              </button>
            </li>
            {props.examDrop && (
              <>
                <li>
                  <NavLink
                    exact
                    to={`/patient/account/exam/${props.id}/physical_medical`}
                    className="text-sm"
                    activeClassName="text-text-3"
                  >
                    Physical/Medical
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={`/patient/account/exam/${props.id}/blood_work_tests`}
                    activeClassName="text-text-3"
                    className="text-sm"
                  >
                    Blood work/Tests
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={`/patient/account/exam/${props.id}/hair_loss_class`}
                    activeClassName="text-text-3"
                    className="text-sm"
                  >
                    Hair loss class
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={`/patient/account/exam/${props.id}`}
                    activeClassName="text-accent-1"
                    className="text-sm"
                  >
                    Hair loss tests
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </li>
        <li>
          <NavLink
            exact
            to={`/patient/account/chat/${props.id}`}
            activeClassName="text-accent-1"
          >
            Chat
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/patient/account/payment/${props.id}`}
            activeClassName="text-accent-1"
          >
            Payment
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/patient/account/forms/${props.id}`}
            activeClassName="text-accent-1"
          >
            Forms
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/patient/account/marketing/${props.id}`}
            activeClassName="text-accent-1"
          >
            Marketing
          </NavLink>
        </li>
      </ul>
    </aside>
  );
}
