import React from 'react'
import Logo from "../assets/images/logo.png";

const PitchVideo = () => {
    return (
            <main
                className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider md:px-10`}
            >
                <nav className="flex items-center border-b-2 border-background-2 pb-4">
                    <img className="h-20" src={Logo} alt="GRO Track" />
                </nav>
                <div className="flex flex-col items-center justify-center flex-grow gap-10">
                    <h1 className="mx-auto mt-4 text-lg sm:text-2xl text-text-2 font-font-2 font-bold text-center">
                        Grow your practice with GroTrack
                    </h1>
                    <div className="w-full h-full">
                        <iframe
                            className="w-full h-full pitch-video"
                            src={`https://www.youtube.com/embed/lYNIpn1WN2Q?si=I6D_fU8v_hFeQ1v6`}
                            frameBorder="0"
                            allowFullScreen
                            title="YouTube video player"
                        ></iframe>
                    </div>
                </div>
            </main>
    )
}

export default PitchVideo;