import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import Loading from './Loading';
import Logo from "../assets/images/logo.png";

const AdminTraining = () => {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [physician, setPhysician] = useState(null);

    const getAdminProfile = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/admin/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setPhysician(response.adminProfile);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    useEffect(() => {
        getAdminProfile();
    }, []);

    return (
        <>
        <main
          className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
            ready ? "" : "hidden"
          }`}
        >
          <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
              <NavLink to="/">
                  <img className="h-20" src={Logo} alt="GRO Track" />
              </NavLink>
              <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
                  {physician && (physician.clinic.name) }
              </h1>
          </nav>
          {message && <p className="mt-8 text-center text-red-600">{message}</p>}
          {success && (
            <p className="mt-8 text-center text-green-600">{success}</p>
          )}
          <section className="flex">
              <AdminSidebar />
              <div className="w-full p-4 flex flex-col items-center justify-center gap-40" style={{ minHeight: 'calc(100vh - 138px)' }}>
                <div className='text-center'>
                    <h1 className='text-2xl text-gray-300'>Grotrack Camera Operation</h1>
                    <h1 className='text-2xl text-gray-300 mb-10'>Video Tranining Program</h1>
                    <NavLink to={`/admin/training/how_to_use_grotrack_camera`} className='bg-accent-1 text-white hover:underline focus:outline-none focus:underline px-16 py-3 rounded text-xl'>START</NavLink>
                </div>
                <div className='text-center'>
                    <h1 className='text-2xl text-gray-300'>Grotrack Software</h1>
                    <h1 className='text-2xl text-gray-300 mb-10'>Guided Tutorial</h1>
                    <NavLink to={`/admin/training`} className='bg-accent-1 text-white hover:underline focus:outline-none focus:underline px-16 py-3 rounded text-xl'>START</NavLink>
                </div>
              </div>
          </section>
        </main>
        <Loading message={"Loading  Details"} ready={ready} />
      </>
    )
}

export default AdminTraining;