import moment from "moment";
import { React, useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Loading from "../views/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function PatientNew() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(true);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (dob) {
      setAge(moment().diff(moment(dob, "DD MMM YYYY"), "years"));
    }
  }, [dob]);

  const isPatientValid = () => {
    return true;
  };

  const registerPatient = () => {
    if (isPatientValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/patient/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          gender: gender,
          dob: moment(dob).format("YYYY-MM-DD"),
          age: age,
          phone: phone.trim(),
          email: email.trim().toLowerCase(),
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setRedirect(response.patientID);
          } else {
            setMessage(response.message);
            setReady(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  if (redirect) {
    return <Redirect to={`/patient/account/profile/${redirect}`} />;
  }

  return (
    <>
      <main
        className={`container mx-auto flex flex-col p-4 text-text-2 ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex pb-6 border-b-2 border-background-2">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
        </nav>
        <h1 className="mt-16 text-center text-xl">CREATE NEW PATIENT</h1>
        {message && <p className="mt-4 text-center text-red-600">{message}</p>}
        <form
          className="mx-auto mt-16 flex flex-col items-end"
          onSubmit={(event) => {
            event.preventDefault();
            registerPatient();
          }}
        >
          <label className="flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">First Name</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={firstName}
              required
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </label>
          <label className="flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">Last Name</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={lastName}
              required
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </label>
          <div className="ml-6 self-start flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">Gender</p>
            <div className="ml-6 flex flex-col md:flex-row">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  required
                  value="M"
                  checked={gender === "M"}
                  onChange={() => {
                    setGender("M");
                  }}
                />
                <p className="ml-4">Male</p>
              </label>
              <label className="md:ml-20 flex items-center">
                <input
                  type="radio"
                  name="gender"
                  required
                  value="F"
                  checked={gender === "F"}
                  onChange={() => {
                    setGender("F");
                  }}
                />
                <p className="ml-4">Female</p>
              </label>
            </div>
          </div>
          <div className="mt-2 flex flex-col items-end md:flex-row">
            <label className="flex items-center">
              <p className="ml-2">DOB</p>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                className="ml-6 w-40 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                selected={dob}
                onChange={(date) => setDOB(date)}
              />
            </label>
            <label className="mt-4 md:mt-0 md:ml-5 flex items-center">
              <p className="ml-5">Age</p>
              <input
                type="text"
                className="ml-6 w-40 md:w-32 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                value={age}
                disabled
                // onChange={(event) => {
                //   setAge(event.target.value);
                // }}
              />
            </label>
          </div>
          <label className="mt-2 flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">Phone</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={phone}
              required
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
          </label>
          <label className="mt-2 flex items-center">
            <p className="ml-2">Email</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </label>
          <input
            className="mt-8 px-8 py-1 bg-accent-1 rounded-lg text-text-3 cursor-pointer focus:outline-none"
            type="submit"
            value="CREATE"
          />
        </form>
      </main>
      <Loading message="Registering Patient" ready={ready} />
    </>
  );
}
